import React, { useState } from "react";
import { Group, Text, rem, Button, TextInput } from "@mantine/core";
import { IconUpload, IconFileTypeCsv, IconX } from "@tabler/icons-react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import * as dfd from "danfojs";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
const VerifyCSV = (props) => {
  const [files, setFiles] = useState([]);

  const [newCSVData, setNewCSVData] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle file drop
  const handleDrop = (droppedFiles) => {
    setFiles(droppedFiles);

    droppedFiles.forEach((file) => {
      // Read the file as text
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result; // Get the content of the CSV file

        // Process CSV with the hash
        await processCSVWithHash(content);
        // Log the new CSV string with the 'b' column
      };
      reader.readAsText(file);
    });
  };

  // SHA3-256 hash function
  const sha3_256_hash_string = (data) => {
    const { sha3_256 } = require("js-sha3");
    const encoder = new TextEncoder();
    const byteArray = encoder.encode(data); // Convert string to bytes (Uint8Array)
    const hash = sha3_256.update(byteArray).hex(); // Compute the hash
    return "0x" + hash; // Add '0x' prefix
  };

  // CSV to JSON conversion
  const csvToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const json = lines.slice(1).reduce((acc, line) => {
      if (line.trim() === "") return acc; // if empty lines, skip
      const values = line.split(",");
      const obj = headers.reduce((headerAcc, header, idx) => {
        headerAcc[header.trim()] = values[idx]?.trim(); // Map object keys to values
        return headerAcc;
      }, {});
      acc.push(obj);
      return acc;
    }, []);
    return json;
  };

  // Process CSV and add SHA3-256 hash
  const processCSVWithHash = async (csvData) => {
    const jsonData = csvToJson(csvData);
    const result = jsonData.map((data) => {
      const { sym, wt, t } = data;
      return {
        t,
        c: sha3_256_hash_string(`${sym},${wt}`),
      };
    });

    const df = new dfd.DataFrame(result);
    const newCsvString = await dfd.toCSV(df); // Convert DataFrame back to CSV
    console.log(newCsvString);
    setNewCSVData(newCsvString);
    form.setValues({ new_csv: newCsvString });
    return newCsvString;
  };

  // Previews (only one file)
  const preview = files.length > 0 ? files[0].name : null;

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      new_csv: "",
    },
  });

  return (
    <div id="verify-method-csv__screen" className="rb-method-csv__screen">
      <div className="rb-method">
        <div className="hash-screen-container rb-container">
          <Dropzone
            styles={{
              root: { "padding-top": "25px" },
            }}
            onDrop={handleDrop}
            onReject={(files) => console.log("rejected files", files)}
            accept={[MIME_TYPES.csv]}
            {...props}
          >
            <Group
              styles={{
                root: { display: "block" },
              }}
              justify="center"
              gap="xs"
              mih={50}
              style={{ pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-blue-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-red-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconFileTypeCsv
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-dimmed)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <div>
                <Text
                  size="xl"
                  inline
                  styles={{
                    root: { "padding-top": "10px" },
                  }}
                >
                  Upload your CSV file
                </Text>
                <Text
                  size="sm"
                  c="dimmed"
                  inline
                  mt={7}
                  styles={{
                    root: { "padding-top": "10px" },
                  }}
                >
                  You can only upload a CSV file with the appropriate structure
                </Text>
              </div>
            </Group>
          </Dropzone>
          {preview && (
            <div>
              File Uploaded: <b>{preview}</b>
            </div>
          )}
          {newCSVData && (
            <form
              onSubmit={form.onSubmit(async (values) => {
                try {
                  setLoading(true);

                  const csrfToken = document.querySelector(
                    "[name=csrfmiddlewaretoken]",
                  ).value;
                  // Create FormData to send to the server

                  const formData = new FormData();
                  formData.append(
                    "file",
                    new Blob([values.new_csv], { type: "text/csv" }),
                    "file.csv",
                  );

                  const metadata = {
                    collection_name: "strategy20241210141616",
                    user_address: "0x2aB93313F9bBa5634d1dfAdd88e7DDD45FAA551B",
                  };

                  formData.append("metadata", JSON.stringify(metadata));

                  try {
                    const response = await fetch(
                      "/verify/verify-user-collection/",
                      {
                        method: "POST",
                        headers: {
                          "X-CSRFToken": csrfToken, // Include the CSRF token
                        },
                        body: formData,
                      },
                    );

                    if (response.ok) {
                      response.json().then((data) => {
                        console.log(data);
                        notifications.show({
                          title: "CSV file sent on server",
                          color: "green",
                        });
                        setFiles([]);
                        setNewCSVData("");
                        form.reset();
                      });
                    } else {
                      response.json().then((data) => {
                        console.log(data.error);
                      });
                    }
                  } catch (error) {
                    console.error("Error:", error);
                    alert("Error uploading CSV");
                  } finally {
                    setLoading(false);
                  }
                } catch (error) {
                  console.error("Submission failed:", error);
                }
              })}
            >
              <Group direction="column" grow>
                <TextInput
                  type="hidden"
                  value={newCSVData}
                  key={form.key("new_csv")}
                  {...form.getInputProps("new_csv")}
                />
                <Button
                  type="submit"
                  color="#1B2F28"
                  loading={loading}
                  styles={{
                    root: {
                      display: "block",
                      maxWidth: "none",
                    },
                  }}
                >
                  Submit CSV
                </Button>
              </Group>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyCSV;
