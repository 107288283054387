import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tabs from "../components/user-profile/Tabs";
import TabContentUserProfile from "../components/user-profile/TabContentUserProfile";
import UserAddressHistory from "../components/user-profile/UserAddressHistory";
import UserDelete from "../components/user-profile/UserDelete";
import UserChangePassword from "../components/user-profile/UserChangePassword";
import ConfirmationLoadingCollectionCreating from "../components/ConfirmationLoadingCollectionCreating";

const UserProfile = ({ staticBasePath, djangoContext }) => {
  const [changedUserName, setChangedUserName] = useState(false);

  const [activeTabUserProfile, setActiveTabUserProfile] = useState(
    window.location.hash || "#user-info",
  );

  const [userAddressHistoryData, setUserAddressHistoryData] = useState("");

  const [userDelete, setUserDelete] = useState(false);

  const [changePassword, setChangePassword] = useState(false);

  // Update active tab based on URL hash change
  useEffect(() => {
    const handleHashChangeUserProfile = () => {
      setActiveTabUserProfile(window.location.hash || "#user-info");
    };

    window.addEventListener("hashchange", handleHashChangeUserProfile);

    return () => {
      window.removeEventListener("hashchange", handleHashChangeUserProfile);
    };
  }, []);

  // Function to switch tab and update URL hash
  const switchTabUserProfile = (tabId) => {
    window.location.hash = tabId;
    setActiveTabUserProfile(tabId);
  };

  // Update user Quota after creating Collection
  const [updateStampQuota, setUpdateStampQuota] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleUpdateStampQuota = useCallback((date) => {
    setUpdateStampQuota(date);
  }, []);

  // Show confirmation create Collection
  const [
    showConfirmationCollectionCreate,
    setShowConfirmationCollectionCreate,
  ] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleShowConfirmationCollectionCreate = useCallback((isDisabled) => {
    setShowConfirmationCollectionCreate(isDisabled);
  }, []);

  //State for storing the name
  const [nameCollection, setNameCollection] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollection = useCallback((collection) => {
    setNameCollection(collection);
  }, []);

  //Error adding name
  const [nameCollectionError, setNameCollectionError] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollectionError = useCallback((collectionError) => {
    setNameCollectionError(collectionError);
  }, []);

  //Successfully added name
  const [nameCollectionSuccess, setNameCollectionSuccess] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameCollectionSuccess = useCallback((collectionSuccess) => {
    setNameCollectionSuccess(collectionSuccess);
  }, []);

  return (
    <>
      {showConfirmationCollectionCreate && (
        <div className="rb-screen">
          <ConfirmationLoadingCollectionCreating
            titleLoading="One moment! Creating new Collection in progress..."
            textLoading="Can take 20 seconds, but usually faster!"
            titleConfirmation="Confirm New Collection"
            textConfirmation="This Collection will be permanently associated with your Blockchain User ID. This operation costs 1 stamp credit."
            nameCollection={nameCollection}
            onNameCollection={handleNameCollection}
            onNameCollectionSuccess={handleNameCollectionSuccess}
            onNameCollectionError={handleNameCollectionError}
            onShowConfirmationCollectionCreate={
              handleShowConfirmationCollectionCreate
            }
            onUpdateStampQuota={handleUpdateStampQuota}
          />
        </div>
      )}

      <Header
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
        changedUserName={changedUserName}
        updateStampQuota={updateStampQuota}
      />

      <div className="rb-main-content user-profile">
        <div className="rb-container">
          <div className="user-profile-header">
            <h1 className="rb-header rb-header-stamp rb-text-center">
              User Profile
            </h1>
          </div>
        </div>
        <Tabs
          activeTabUserProfile={activeTabUserProfile}
          switchTabUserProfile={switchTabUserProfile}
        />
        <div className="user-profile-tabs-content">
          <div className="rb-container">
            <TabContentUserProfile
              activeTabUserProfile={activeTabUserProfile}
              setUserAddressHistoryData={setUserAddressHistoryData}
              setUserDelete={setUserDelete}
              setChangePassword={setChangePassword}
              setChangedUserName={setChangedUserName}
              changedUserName={changedUserName}
              staticBasePath={staticBasePath}
              onUpdateStampQuota={handleUpdateStampQuota}
              onShowConfirmationCollectionCreate={
                handleShowConfirmationCollectionCreate
              }
              showConfirmationCollectionCreate={
                showConfirmationCollectionCreate
              }
              onNameCollection={handleNameCollection}
              nameCollection={nameCollection}
              nameCollectionSuccess={nameCollectionSuccess}
              onNameCollectionError={handleNameCollectionError}
              nameCollectionError={nameCollectionError}
            />
          </div>
        </div>
        {showConfirmationCollectionCreate && (
          <div className="rb-loading-bg"></div>
        )}
      </div>

      <Footer staticBasePath={staticBasePath} />

      {userAddressHistoryData ? (
        <UserAddressHistory
          staticBasePath={staticBasePath}
          userAddressHistoryData={userAddressHistoryData}
          setUserAddressHistoryData={setUserAddressHistoryData}
        />
      ) : (
        ""
      )}

      {userDelete ? (
        <UserDelete
          staticBasePath={staticBasePath}
          setUserDelete={setUserDelete}
        />
      ) : (
        false
      )}

      {changePassword ? (
        <UserChangePassword
          staticBasePath={staticBasePath}
          setChangePassword={setChangePassword}
        />
      ) : (
        false
      )}
    </>
  );
};
UserProfile.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
  onUpdateStampQuota: PropTypes.bool,
  showConfirmationCollectionCreate: PropTypes.func,
  handleNameCollection: PropTypes.func,
};

export default UserProfile;
