import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  handleButtonClickUserAddressHistory,
  submitFormChangeUserName,
} from "../../utils/userProfile";
import { URLs } from "../../utils/apis";

import { useForm } from "@mantine/form";
import { TextInput, Button } from "@mantine/core";

const UserInfo = ({
  setUserAddressHistoryData,
  setUserDelete,
  setChangePassword,
  setChangedUserName,
  changedUserName,
}) => {
  const [enableName, setEnableName] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      email: "",
      last_address: "",
      date_joined: "",
      last_is_verified: "Unverified",
    },
    enhanceGetInputProps: (payload) => {
      if (enableName && payload.field === "name") {
        return { disabled: false };
      }
      return { disabled: true };
    },
    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) => {
        if (value.length < 3) {
          return "Input must be at least 3 characters";
        }
        if (value.length > 70) {
          return "Input must be less than 70 characters";
        }

        const regex = /^[\w\s!@#$%&*()_\-+=.,'":;?]+$/;
        if (!regex.test(value)) {
          return "Input contains invalid characters. Allowed characters: ! @ # $ % & * ( ) _ - + = . , ' \" : ; ?";
        }
        return null;
      },
    },
  });

  // Temporarily hide items in development
  const [hideInProgress, setHideInProgress] = useState(true); // eslint-disable-line no-unused-vars

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Fetch user data from API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(URLs.USER_INFO_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const modifiedData = {
          ...data,
          last_is_verified: data.last_is_verified ? "Verified" : "Unverified",
        };
        form.initialize(modifiedData);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [changedUserName]); // Updates after the Username changes

  const handleButtonClickUserDelete = () => {
    setUserDelete(true);
  };

  const handleButtonClickChangePassword = () => {
    setChangePassword(true);
  };
  if (isLoading) {
    return (
      <div className="rb-display-flex rb-justify-content-center">
        Loading...
      </div>
    ); // Show a loading message while data is being fetched
  }

  return (
    <div className="user-profile-tabs-content-item user-info">
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            await submitFormChangeUserName(
              values,
              "name",
              form,
              setEnableName,
              setChangedUserName,
            );
          } catch (error) {
            console.error("Submission failed:", error);
          }
        })}
      >
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <TextInput
            classNames={{
              root: "rb-display-flex rb-justify-content-center rb-align-items-center",
              label: "pr-20",
              input: "pr-20 rb-input",
            }}
            label="Display Name:"
            inputWrapperOrder={["label", "input"]}
            inputContainer={(children) => (
              <div className="rb-input-wrap">
                {children}
                <div className="rb-input-text-error">{form.errors.name}</div>
              </div>
            )}
            placeholder="Name"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />

          {enableName ? (
            <button>Save</button>
          ) : (
            <Button
              type="button"
              variant="transparent"
              color="#000"
              onClick={() => setEnableName((prev) => !prev)}
            >
              Change
            </Button>
          )}
        </div>

        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <TextInput
            classNames={{
              root: "rb-display-flex rb-justify-content-center rb-align-items-center",
              label: "pr-20",
              input: "pr-20 rb-input",
            }}
            label="E-mail Address:"
            placeholder="Email"
            key={form.key("email")}
            {...form.getInputProps("email")}
          />
          <button></button>
        </div>
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <TextInput
            classNames={{
              root: "rb-display-flex rb-justify-content-center rb-align-items-center",
              label: "pr-20",
              input: "pr-20 rb-input",
            }}
            label="Current User Address:"
            placeholder="Current User Address:"
            key={form.key("last_address")}
            {...form.getInputProps("last_address")}
          />
          <button
            onClick={() =>
              handleButtonClickUserAddressHistory(setUserAddressHistoryData)
            }
          >
            User Address History
          </button>
        </div>
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <TextInput
            classNames={{
              root: "rb-display-flex rb-justify-content-center rb-align-items-center",
              label: "pr-20",
              input: "pr-20 rb-input",
            }}
            label="Identity Verification Status:"
            placeholder="Identity Verification Status:"
            key={form.key("last_is_verified")}
            {...form.getInputProps("last_is_verified")}
          />
          <button></button>
        </div>
        <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
          <TextInput
            classNames={{
              root: "rb-display-flex rb-justify-content-center rb-align-items-center",
              label: "pr-20",
              input: "pr-20 rb-input",
            }}
            label="Account Created on Date:"
            placeholder="Account Created on Date:"
            key={form.key("date_joined")}
            {...form.getInputProps("date_joined")}
          />
          <button></button>
        </div>
      </form>

      {!hideInProgress && (
        <>
          <div className="user-profile-line user-profile-line-password rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <button onClick={() => handleButtonClickChangePassword()}>
              Change Password
            </button>
          </div>

          <div className="user-profile-line user-profile-line-cancel rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <button onClick={() => handleButtonClickUserDelete()}>
              Cancel Account
            </button>
          </div>
        </>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  setUserAddressHistoryData: PropTypes.func.isRequired,
  setUserDelete: PropTypes.func,
  setChangePassword: PropTypes.func,
  setChangedUserName: PropTypes.func,
  changedUserName: PropTypes.string,
};

export default UserInfo;
