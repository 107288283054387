import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import debounce from "lodash/debounce";
import Loading from "../components/Loading";
import StampError from "../components/StampError";
import StampCreated from "../components/StampCreated";
import IconStampFromFile from "../svg/StampFromFile";
import IconStampContentId from "../svg/StampContentId";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StampContentId from "../components/StampContentId";
import StampFromFile from "../components/StampFromFile";
import { processFile } from "../utils/processFile";
import { verifyHashWorker } from "../utils/verifyHashWorker";
import { pingForwarder } from "../utils/pingForwarder";
import TriggerHelp from "../components/TriggerHelp";
import NeedHelp from "../components/NeedHelp";
import Help from "../components/Help";
import Landing from "../components/landing-components/Index";
import LearnMore from "../components/landing-components/LearnMore";
import {
  fetchAllCollectionsIsPinnedWithoutPagination,
  fetchQuotaData,
} from "../utils/apis";
import SelectPopUpCollection from "../components/SelectPopUpCollection";

const StampForm = ({ staticBasePath, djangoContext }) => {
  // Collection hash
  const [selectedOptionCollection, setSelectedOptionCollection] =
    useState(null);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleSelectedOptionCollection = useCallback((option) => {
    setSelectedOptionCollection(option);
  }, []);

  // set All Collection Names
  const [selectCollections, setSelectCollections] = useState(null);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleSelectCollections = useCallback((Collections) => {
    setSelectCollections(Collections);
    setActivateSelectCollection(Collections && Collections.length > 0);
  }, []);

  // fetch all Collection Names for select without pagination
  useEffect(() => {
    fetchAllCollectionsIsPinnedWithoutPagination(handleSelectCollections);
  }, []);

  // Collection activate checkbox
  const [activateSelectCollection, setActivateSelectCollection] =
    useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleActivateSelectCollection = useCallback((isChecked) => {
    setActivateSelectCollection(isChecked);
  }, []);

  const [helpIsActive, setHelpIsActive] = useState(() => {
    const cookieValue = Cookies.get("helpIsActive");
    return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
  });

  const handleHelpTriggerClick = () => {
    const newHelpIsActive = !helpIsActive;
    setHelpIsActive(newHelpIsActive);
    Cookies.set("helpIsActive", JSON.stringify(newHelpIsActive));
  };

  const [verificationMethod, setVerificationMethod] = useState(() => {
    const cookieVerificationMethod = Cookies.get("verificationMethod");

    if (
      cookieVerificationMethod === undefined ||
      JSON.parse(cookieVerificationMethod) === "user" ||
      JSON.parse(cookieVerificationMethod) === "csv"
    ) {
      return "file";
    } else {
      return JSON.parse(cookieVerificationMethod);
    }
  });
  const [objectHash, setObjectHash] = useState(null);
  const [stampData, setStampData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(""); // new state to manage screen
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = djangoContext.userIsAuthenticated;
  const [button, setButton] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const regex = /^(0x)?[0-9a-fA-F]{64}$/;
  let timeout = null;
  let timeoutDuration = 10 * 60 * 1000; //1 minute
  let lastTimeoutInvocation = Date.now();
  const onMouseMove = () => {
    if (!isAuthenticated) {
      return;
    }
    restartAutoReset();
  };
  const restartAutoReset = () => {
    // Check if the timeout has already occurred
    let isTimedOut =
      Date.now() > new Date(lastTimeoutInvocation + timeoutDuration);

    if (isTimedOut) {
      lastTimeoutInvocation = Date.now();
      pingForwarder(isAuthenticated);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        pingForwarder(isAuthenticated);
        clearTimeout(timeout);
      }, 0);
    }
  };

  const handleVerificationMethodChange = (method) => {
    if (method !== verificationMethod) {
      setCurrentScreen("");
      setObjectHash("");
      setIsValid(true);
      setButton(false);
    }
    setVerificationMethod(method);
    Cookies.set("verificationMethod", JSON.stringify(method));
  };

  const handleDefaultScreen = () => {
    setButton(false);
    setError(false);
    setCurrentScreen("");
    setObjectHash("");
    setLoading(false);
  };

  const handleFileInput = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const file = event.target.files?.[0] || event.dataTransfer?.files?.[0];
      setButton(false);
      if (file) {
        setStampData(null);
        setObjectHash("");
        handleProcessFile(file);
      }
    }
  };

  const handleHashChange = (event) => {
    const value = event.target.value;
    if (isAuthenticated) {
      const isValid = regex.test(value);
      setIsValid(isValid || value === "");
      setObjectHash(value);
      setButton(false);
      if (!isLoading && value !== "" && isValid) {
        handleDebouncedUpdateHashWorker(value);
      }
      {
        setCurrentScreen("");
      }
    }
  };
  const handleDebouncedUpdateHashWorker = useCallback(
    debounce((value) => handleVerifyHashWorker(value), 2000),
    [handleVerifyHashWorker],
  );
  const handleRemoveHash = () => {
    setCurrentScreen("");
    setObjectHash("");
    setButton(false);
    setIsValid(true);
  };
  const handleCreateSubmit = () => {
    // Show pop up confirmation create stamp
    setSelectPopUpShow(true);
  };

  // Add all Hashes supported
  const [allHashes, setAllHashes] = useState({});
  const handleProcessFile = (file) => {
    // get hash with web3
    processFile(file, setObjectHash, handleVerifyHashWorker, setAllHashes);
  };
  const handleVerifyHashWorker = (hash, supportedHashes, created = false) => {
    // fetch /verify/hash/
    verifyHashWorker(
      hash,
      supportedHashes,
      created,
      setIsLoading,
      setError,
      setStampData,
      setCurrentScreen,
      setButton,
      setLoading,
    );
  };

  const buttonStampClassName = `rb-btn rb-btn-click rb-btn-stamp rb-display-flex rb-align-items-center ${
    button ? "" : "rb-btn-disabled"
  } ${
    !selectedOptionCollection && activateSelectCollection === true
      ? "rb-btn-disabled"
      : ""
  }`;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 639);
  const isDisabled = !objectHash || (objectHash && currentScreen === "found");

  const handlePingForwarder = useCallback(() => {
    pingForwarder(isAuthenticated);
  }, [isAuthenticated]);
  useEffect(() => {
    handlePingForwarder();
    window.removeEventListener("mousemove", onMouseMove);
    window.addEventListener("mousemove", onMouseMove);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 639);
    };
    restartAutoReset(0);
    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [handlePingForwarder]);

  const isOpenNotification = true;
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const rbMainContent = document.querySelector(".rb-main-content");

    if (!rbMainContent) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const contentOffsetTop = rbMainContent.offsetTop;
    const contentHeight = rbMainContent.offsetHeight;
    const windowHeight = window.innerHeight;
    const remainingScroll =
      contentOffsetTop + contentHeight - (scrollTop + windowHeight);

    if (
      (remainingScroll <= 80 && window.innerWidth <= 639) ||
      (remainingScroll <= 8 && window.innerWidth >= 640)
    ) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpenNotification]);

  useEffect(() => {
    const rbRoot = document.querySelector(".rb-root");
    if (currentScreen === "created" || error) {
      rbRoot.classList.remove("full-screen-min-height");
    }

    return () => {
      rbRoot.classList.remove("full-screen-min-height");
    };
  }, [currentScreen, error]);

  const landingSectionRef = useRef(null);

  //Quota used 100%
  const [quotaUsed100, setQuotaUsed100] = useState(false);
  const [quotaUsageInfo, setQuotaUsageInfo] = useState(0);
  console.log(quotaUsageInfo);
  useEffect(() => {
    const fetchQuota = async () => {
      try {
        const { quotaUsageInfo } = await fetchQuotaData(); // Use the utility function to get data
        setQuotaUsageInfo(quotaUsageInfo);

        // Show the banner if usage exceeds 90%, not on the payment page, and the banner wasn't hidden
        if (quotaUsageInfo === 0) {
          setQuotaUsed100(true);
        }
      } catch (error) {
        console.log(error); // Handle any errors
      }
    };
    if (currentScreen === "created") {
      fetchQuota(); // Call the function to fetch quota data
    }
    fetchQuota(); // Call the function to fetch quota data
  }, [currentScreen]);

  //Show pop up
  const [selectPopUpShow, setSelectPopUpShow] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleSelectPopUpShow = useCallback((bool) => {
    setSelectPopUpShow(bool);
  }, []);

  return (
    <>
      <Header
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
        currentScreen={currentScreen}
      />

      <div className="rb-main-content">
        {error ? (
          <StampError
            error={error}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
          />
        ) : currentScreen === "created" ? (
          <StampCreated
            data={stampData}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
            allHashes={allHashes}
          />
        ) : (
          <>
            <div className="rb-screen">
              {loading && (
                <Loading text="One moment! Stamping in progress..." />
              )}

              {selectPopUpShow && (
                <SelectPopUpCollection
                  onSelectPopUpShow={handleSelectPopUpShow}
                  selectedOptionCollection={selectedOptionCollection}
                  objectHash={objectHash}
                  setLoading={setLoading}
                  setError={setError}
                  handleVerifyHashWorker={handleVerifyHashWorker}
                  handleSelectedOptionCollection={
                    handleSelectedOptionCollection
                  }
                />
              )}

              <div className="rb-header-wrap">
                <p className="rb-header rb-text-center rb-mt-xl">
                  Make a <b>vBase</b> Stamp
                </p>
                <p className="rb-subheader rb-text-center">
                  A verifiable tamperproof timestamp for your data
                </p>
                <NeedHelp
                  staticBasePath={staticBasePath}
                  linkDocs="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                />
              </div>
              <div className="rb-method-wrapper">
                <div
                  id="verify-method-chooser"
                  className={`rb-btns-select rb-mt-normal ${
                    isAuthenticated ? "" : "rb-btns-select-disabled"
                  }
                   ${quotaUsed100 ? "rb-btns-select-disabled" : ""}`}
                  data-method={verificationMethod}
                >
                  <button
                    id="verify-method-file"
                    className={`rb-btn rb-btn-file ${
                      verificationMethod === "file" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => {
                      if (isAuthenticated && !quotaUsed100) {
                        handleVerificationMethodChange("file");
                      }
                    }}
                  >
                    <IconStampFromFile />
                    From File
                  </button>
                  <button
                    id="verify-method-hash"
                    className={`rb-btn rb-btn-hash ${
                      verificationMethod === "hash" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => {
                      if (isAuthenticated && !quotaUsed100) {
                        handleVerificationMethodChange("hash");
                      }
                    }}
                  >
                    <IconStampContentId />
                    Content ID
                  </button>
                  {helpIsActive && (
                    <Help
                      position="right"
                      text="Choose whether to calculate a digital fingerprint from a local file, or input a digital fingerprint/content ID directly (advanced)."
                      link="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                    />
                  )}
                </div>
              </div>

              {verificationMethod === "hash" && (
                <StampContentId
                  currentScreen={currentScreen}
                  staticBasePath={staticBasePath}
                  isValid={isValid}
                  objectHash={objectHash}
                  handleHashChange={handleHashChange}
                  handleRemoveHash={handleRemoveHash}
                  handleCreateSubmit={handleCreateSubmit}
                  isDisabled={isDisabled}
                  isSmallScreen={isSmallScreen}
                  isAuthenticated={isAuthenticated}
                  buttonStampClassName={buttonStampClassName}
                  quotaUsed100={quotaUsed100}
                  helpIsActive={helpIsActive}
                  onSelectedOptionCollection={handleSelectedOptionCollection}
                  selectedOptionCollection={selectedOptionCollection}
                  activateSelectCollection={activateSelectCollection}
                  onActivateSelectCollection={handleActivateSelectCollection}
                  selectCollections={selectCollections}
                />
              )}

              {verificationMethod === "file" && (
                <StampFromFile
                  currentScreen={currentScreen}
                  staticBasePath={staticBasePath}
                  objectHash={objectHash}
                  handleFileInput={handleFileInput}
                  isAuthenticated={isAuthenticated}
                  isDisabled={isDisabled}
                  helpIsActive={helpIsActive}
                  quotaUsed100={quotaUsed100}
                  onSelectedOptionCollection={handleSelectedOptionCollection}
                  selectedOptionCollection={selectedOptionCollection}
                  activateSelectCollection={activateSelectCollection}
                  onActivateSelectCollection={handleActivateSelectCollection}
                  selectCollections={selectCollections}
                />
              )}

              {(!isSmallScreen || verificationMethod === "file") && (
                <div className="rb-info rb-flex-wrap-wrap">
                  <button
                    className={buttonStampClassName}
                    onClick={handleCreateSubmit}
                    disabled={
                      !button ||
                      (!selectedOptionCollection &&
                        activateSelectCollection === true)
                    }
                  >
                    Make a vBase Stamp!
                  </button>

                  {!isAuthenticated && (
                    <div className="rb-stamper-login rb-display-flex rb-justify-content-center w-100">
                      <a href="/accounts/login/">Sign In</a> to Start Stamping!
                    </div>
                  )}
                  {helpIsActive && (
                    <Help
                      position="right"
                      text="Once you click “Make a vBase Stamp” the digital fingerprint of your data will be included in a blockchain transaction, creating a trusted verifiable timestamp!"
                      link="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                    />
                  )}
                </div>
              )}
              <div className="stamp-bulk__text">
                Need to stamp in bulk?{" "}
                <a
                  href="https://docs.vbase.com/getting-started/python-quickstart"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check out our handy API
                </a>
              </div>
            </div>
            <TriggerHelp
              helpIsActive={helpIsActive}
              isNearBottom={isNearBottom}
              handleHelpTriggerClick={handleHelpTriggerClick}
            />
          </>
        )}
        <LearnMore
          staticBasePath={staticBasePath}
          landingSectionRef={landingSectionRef}
        />
      </div>
      <Landing
        staticBasePath={staticBasePath}
        isAuthenticated={isAuthenticated}
        landingSectionRef={landingSectionRef}
      />

      <Footer staticBasePath={staticBasePath} />
      {loading && currentScreen !== "created" && (
        <div className="rb-loading-bg"></div>
      )}
      {selectPopUpShow && currentScreen !== "created" && (
        <div className="rb-loading-bg"></div>
      )}
    </>
  );
};

StampForm.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
  setAuthenticated: PropTypes.func,
  event: PropTypes.object,
  setIsOpenNotification: PropTypes.func,
  quotaUsageInfo: PropTypes.object,
};

export default StampForm;
