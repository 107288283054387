import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import QRCode from "qrcode";

const DownloadPDF = ({ data, formattedDateTime, allHashes }) => {
  // TODO remove it
  const blockchainName = "Polygon";
  const smartContractID = "0x80D2AB15EE5b91cD7A183b8938dC277fE6191F7d";

  // Custom font for pdf
  const HelveticaLightRegular =
    "/static/assets/fonts/Helvetica_Light_Regular.otf";

  const {
    user_id,
    user,
    objectCid,
    blockExplorerUrl,
    transactionHash,
    chainId,
  } = data;

  //Link for QR code
  const qrLink = "https://app.vbase.com/verify/?cid=" + objectCid;

  const generatePDF = useCallback(async () => {
    // Load the PDF template
    const templatePDFUrl = "/static/assets/pdf/template_stamp.pdf"; // Path to the PDF template
    const response = await fetch(templatePDFUrl);
    const templateBytes = await response.arrayBuffer(); // Get template data as ArrayBuffer

    const fontBytes = await fetch(HelveticaLightRegular).then((res) =>
      res.arrayBuffer(),
    );

    // Load the PDF document from the template
    const pdfDoc = await PDFDocument.load(templateBytes);

    // Register fontkit
    pdfDoc.registerFontkit(fontkit);

    // Custom font for the PDF
    const helveticaLightFont = await pdfDoc.embedFont(fontBytes);

    // Standard font for the PDF
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the PDF
    const page = pdfDoc.getPages()[0];
    const { width, height } = page.getSize();

    // User
    page.drawText(user, {
      x: 320, // X coordinate
      y: height - 295, // Y coordinate (from top to bottom)
      size: 22,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    // Blockchain Name
    page.drawText(blockchainName, {
      x: 530, // X coordinate
      y: height - 295, // Y coordinate (from top to bottom)
      size: 22,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    //Timestamp
    page.drawText(formattedDateTime + " +UTC", {
      x: 350, // X coordinate
      y: height - 363, // Y coordinate (from top to bottom)
      size: 26,
      font: font,
      color: rgb(0.2, 0.2, 0.2),
    });

    // User ID
    page.drawText(user_id, {
      x: 240,
      y: height - 928,
      size: 15,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Content ID
    page.drawText(objectCid, {
      x: 240,
      y: height - 952,
      size: 15,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // blockchain Name and blockchain ID
    page.drawText(blockchainName + " (" + chainId + ")", {
      x: 240,
      y: height - 977,
      size: 15,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Transaction ID
    page.drawText(transactionHash, {
      x: 240,
      y: height - 1002,
      size: 15,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    // Smart Contract ID
    page.drawText(smartContractID, {
      x: 240,
      y: height - 1028,
      size: 15,
      font: helveticaLightFont,
      color: rgb(0, 0, 0),
    });

    if (allHashes[objectCid]) {
      // Hashing algorithm used key
      page.drawText("Hashing algorithm used:", {
        x: 53,
        y: height - 1053,
        size: 15,
        font: font,
        color: rgb(0, 0, 0),
      });

      // Hashing algorithm used value
      page.drawText(allHashes[objectCid], {
        x: 240,
        y: height - 1053,
        size: 15,
        font: helveticaLightFont,
        color: rgb(0, 0, 0),
      });
    }

    // Convert data URL directly to an ArrayBuffer
    function dataURLToArrayBuffer(dataURL) {
      const binaryString = atob(dataURL.split(",")[1]); // Get the base64 part of the data URL
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

    // Generate QR code with the link
    const qrCodeDataUrl = await QRCode.toDataURL(qrLink);
    const qrCodeImageBytes = dataURLToArrayBuffer(qrCodeDataUrl); // Convert data URL to ArrayBuffer
    const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

    // Insert QR code into PDF (bottom right on the first page)
    const qrCodeSize = 100;
    page.drawImage(qrCodeImage, {
      x: width - qrCodeSize - 45,
      y: 80,
      width: qrCodeSize,
      height: qrCodeSize,
    });

    // Save the edited PDF document
    const pdfBytes = await pdfDoc.save();

    // Create a Blob for the PDF download link
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "stamp-report.pdf";
    link.click();
  }, [user_id, objectCid, blockExplorerUrl, transactionHash]);

  return (
    <button className="download-pdf" onClick={generatePDF}>
      Download PDF
    </button>
  );
};

DownloadPDF.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    chainId: PropTypes.string.isRequired,
    objectCid: PropTypes.string.isRequired,
    blockExplorerUrl: PropTypes.string.isRequired,
    transactionHash: PropTypes.string.isRequired,
  }).isRequired,
  formattedDateTime: PropTypes.string,
  allHashes: PropTypes.object.isRequired,
};

export default DownloadPDF;
