export const updateHashWorkerVerify = async (
  hash,
  supportedHashAlgoMap,
  setError,
  setStampData,
  setCurrentScreen,
  setObjectHash,
) => {
  const url = new URL(window.location);
  url.searchParams.set("cid", hash);
  window.history.pushState({}, "", url);
  // Check if hash exists before adding
  supportedHashAlgoMap ||= {};
  if (!(hash in supportedHashAlgoMap)) {
    supportedHashAlgoMap[hash] = hash;
  }
  // Get list of hashes
  const objectHashes = Object.keys(supportedHashAlgoMap || {});

  const requestData = {
    "object-hashes": objectHashes,
  };

  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  try {
    const response = await fetch("/verify/hash/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();

    if (data.stamp_list) {
      const lastElement = data.stamp_list[data.stamp_list.length - 1];
      setStampData(lastElement);
      setCurrentScreen("found");
      setError(null);
    } else {
      setStampData(null);
      setObjectHash(hash);
      setCurrentScreen("notfound");
      setError(null);
    }
  } catch (error) {
    console.error("Error:", error);
    setError("An error occurred while verifying the stamp.");
  }
};
