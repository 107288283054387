import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useMemo } from "react";
import StampFound from "../components/StampFound";
import StampNotFound from "../components/StampNotFound";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { processFile } from "../utils/processFile";
import { updateHashWorkerVerify } from "../utils/updateHashWorkerVerify";
import VerifyContentId from "../components/VerifyContentId";
import VerifyFile from "../components/VerifyFile";
import Cookies from "js-cookie";
import TriggerHelp from "../components/TriggerHelp";
import Help from "../components/Help";
import NeedHelp from "../components/NeedHelp";
import LearnMore from "../components/landing-components/LearnMore";
import Landing from "../components/landing-components/Index";

const VerifyForm = ({ staticBasePath, djangoContext }) => {
  const [helpIsActive, setHelpIsActive] = useState(() => {
    const cookieValue = Cookies.get("helpIsActive");
    return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
  });

  const handleHelpTriggerClick = () => {
    const newHelpIsActive = !helpIsActive;
    setHelpIsActive(newHelpIsActive);
    Cookies.set("helpIsActive", JSON.stringify(newHelpIsActive));
  };

  const [verificationMethod, setVerificationMethod] = useState(() => {
    const cookieVerificationMethod = Cookies.get("verificationMethod");
    return cookieVerificationMethod !== undefined
      ? JSON.parse(cookieVerificationMethod)
      : "file";
  });
  const [objectHash, setObjectHash] = useState(null);
  const [stampData, setStampData] = useState(null);
  const [error, setError] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("verify"); // new state to manage screen
  const [isValid, setIsValid] = useState(true);
  const regex = /^(0x)?[0-9a-fA-F]{64}$/;
  const isAuthenticated = useMemo(() => djangoContext.userIsAuthenticated, []);
  useEffect(() => {
    const handleLocationChange = () => {
      const params = new URLSearchParams(window.location.search);
      const cidParam = params.get("cid");
      const checkValid = regex.test(cidParam) || cidParam === "";
      setIsValid(checkValid);
      if (checkValid) {
        setObjectHash(cidParam);
        handleUpdateHashWorkerVerify(cidParam, undefined);
      }
    };

    window.addEventListener("popstate", handleLocationChange);
    handleLocationChange();
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const handleDefaultScreen = () => {
    setError(false);
    setCurrentScreen("");
    setObjectHash("");

    const url = new URL(window.location);
    if (url.searchParams.has("cid")) {
      url.searchParams.delete("cid");
      window.history.pushState({}, "", url);
    }
  };

  const handleVerificationMethodChange = (method) => {
    setVerificationMethod(method);
    Cookies.set("verificationMethod", JSON.stringify(method));
  };

  const handleFileInput = (event) => {
    event.preventDefault();
    const file = event.target.files?.[0] || event.dataTransfer?.files?.[0];
    if (file) {
      handleProcessFile(file);
    }
  };

  const handleHashChange = (event) => {
    const { value } = event.target;
    setIsValid(regex.test(value) || value === "");
    setObjectHash(value);
  };
  // TODO: Is this really handleHashUpdate? It confused me since it sounds like making a stamp.
  const handleSubmit = () => {
    if (objectHash !== "") {
      handleUpdateHashWorkerVerify(objectHash, undefined);
    }
  };

  const [allHashes, setAllHashes] = useState({});
  const handleProcessFile = (file) => {
    // get hash with web3
    processFile(
      file,
      setObjectHash,
      handleUpdateHashWorkerVerify,
      setAllHashes,
    );
  };

  const handleUpdateHashWorkerVerify = (hash, supportedHashes) => {
    // fetch /verify/hash/
    updateHashWorkerVerify(
      hash,
      supportedHashes,
      setError,
      setStampData,
      setCurrentScreen,
      setObjectHash,
    );
  };
  const [isNearBottom, setIsNearBottom] = useState(false);
  const handleScroll = () => {
    const rbMainContent = document.querySelector(".rb-main-content");

    if (!rbMainContent) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const contentOffsetTop = rbMainContent.offsetTop;
    const contentHeight = rbMainContent.offsetHeight;
    const windowHeight = window.innerHeight;
    const remainingScroll =
      contentOffsetTop + contentHeight - (scrollTop + windowHeight);

    let heightBottom;

    heightBottom = -28;

    if (
      (remainingScroll <= 80 && window.innerWidth <= 639) ||
      (remainingScroll <= heightBottom && window.innerWidth >= 640)
    ) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const rbRoot = document.querySelector(".rb-root");
    if (currentScreen === "found" || error) {
      rbRoot.classList.remove("full-screen-min-height");
    }

    return () => {
      rbRoot.classList.remove("full-screen-min-height");
    };
  }, [currentScreen, error]);

  const landingSectionRef = useRef(null);
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content">
        {error ? (
          <div className="error-message">{error}</div>
        ) : currentScreen === "found" ? (
          <>
            <StampFound
              key={stampData.id}
              data={stampData}
              staticBasePath={staticBasePath}
              handleDefaultScreen={handleDefaultScreen}
              allHashes={allHashes}
            />
          </>
        ) : currentScreen === "notfound" ? (
          <StampNotFound
            hash={objectHash}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
          />
        ) : (
          <>
            <div className="rb-screen">
              <div className="rb-header-wrap">
                <p className="rb-header rb-text-center">
                  Verify <b>vBase</b> Stamps
                </p>
                <p className="rb-subheader rb-text-center">
                  Validate the author, content and timestamp for any vBase Stamp
                </p>
                <NeedHelp
                  staticBasePath={staticBasePath}
                  linkDocs="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-verify"
                />
              </div>
              <div className="rb-method-wrapper">
                <div
                  id="verify-method-chooser"
                  className="rb-btns-select rb-mt-normal"
                  data-method={verificationMethod}
                >
                  <button
                    id="verify-method-file"
                    className={`rb-btn rb-btn-file ${
                      verificationMethod === "file" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => handleVerificationMethodChange("file")}
                  >
                    Verify File
                  </button>
                  <button
                    id="verify-method-hash"
                    className={`rb-btn rb-btn-hash ${
                      verificationMethod === "hash" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => handleVerificationMethodChange("hash")}
                  >
                    Content ID
                  </button>
                  {helpIsActive && (
                    <Help
                      position="right"
                      text="Choose whether to verify a digital fingerprint by calculating it from a local file, or use advanced mode and input a digital fingerprint/content ID directly."
                      link="#"
                    />
                  )}
                </div>
              </div>

              {verificationMethod === "hash" && (
                <VerifyContentId
                  isValid={isValid}
                  objectHash={objectHash}
                  handleHashChange={handleHashChange}
                  handleSubmit={handleSubmit}
                />
              )}

              {verificationMethod === "file" && (
                <VerifyFile
                  handleFileInput={handleFileInput}
                  staticBasePath={staticBasePath}
                  helpIsActive={helpIsActive}
                />
              )}
              <div className="stamp-bulk__text">
                Need to verify many stamps?{" "}
                <a
                  href="https://docs.vbase.com/getting-started/python-quickstart"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Check out our handy API
                </a>
              </div>
            </div>
            <TriggerHelp
              helpIsActive={helpIsActive}
              isNearBottom={isNearBottom}
              handleHelpTriggerClick={handleHelpTriggerClick}
            />
          </>
        )}
        <LearnMore
          staticBasePath={staticBasePath}
          landingSectionRef={landingSectionRef}
        />
      </div>
      <Landing
        staticBasePath={staticBasePath}
        isAuthenticated={isAuthenticated}
        landingSectionRef={landingSectionRef}
      />
      <Footer staticBasePath={staticBasePath} />
    </>
  );
};
VerifyForm.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
};
export default VerifyForm;
