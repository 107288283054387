import { URLs, fetchUserData } from "./apis";
import { notifications } from "@mantine/notifications";

export const submitFormChangeUserName = async (
  values,
  field,
  form,
  setEnableName,
  setChangedUserName,
) => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({ [field]: values.name }), // Send the specific input value
    });

    if (response.ok) {
      setEnableName(false);
      notifications.show({
        title: "User Updated",
        color: "green",
      });
      setChangedUserName(values.name);
    } else {
      if (response.status === 400) {
        response.json().then((data) => {
          Promise.resolve().then(() =>
            form.setFieldError("name", data.error.name[0].message),
          );
        });
      } else {
        Promise.resolve().then(() =>
          form.setFieldError("name", response.statusText),
        );
      }
    }
  } catch (error) {
    Promise.resolve().then(() =>
      form.setFieldError("name", "Error submitting the form:", error),
    );
    console.error("Error submitting the form:", error);
    throw error;
  }
};

export const handleButtonClickUserAddressHistory = (
  setUserAddressHistoryData,
) => {
  fetchUserData(1, setUserAddressHistoryData);
};

export const handleButtonClickDeleteUser = async () => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  try {
    await fetch(URLs.USER_PROFILE_DELETE_ACCOUNT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to delete account");
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error("Error delete user", error);
  }
};
