import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { handleClickToCopy } from "../utils/copyToClipboard";
import Cookies from "js-cookie";
import TriggerHelp from "../components/TriggerHelp";
import Help from "./Help";
import { formatStampDetails } from "../utils/formatStampDetails";
import {
  keccak256_hash_string,
  sha3_256_hash_string,
} from "../utils/cryptoUtils";
import DownloadPDF from "./DownloadPDF";

const StampFound = ({
  data,
  staticBasePath,
  handleDefaultScreen,
  showVerifyStamp = true,
  allHashes,
}) => {
  // in development
  const [pdfShow] = useState(true);

  // current hash
  const [currentHashVersion, setCurrentHashVersion] = useState("");

  // current hash Collection
  const [currentHashCollectionVersion, setCurrentHashCollectionVersion] =
    useState("");

  useEffect(() => {
    setCurrentHashVersion("");
    if (allHashes[data.objectCid]) {
      setCurrentHashVersion(allHashes[data.objectCid]);
    }

    // check collection hash type
    if (data.collection_name) {
      const hashCollectionSha3 = sha3_256_hash_string(data.collection_name);
      const hashCollectionKeccak256 = keccak256_hash_string(
        data.collection_name,
      );

      const supportedHashTypesCollection = {
        [hashCollectionSha3]: "SHA3-256",
        [hashCollectionKeccak256]: "Keccak-256",
      };

      if (supportedHashTypesCollection[data.collection_hash]) {
        //check exists current hash Collection in all hashes
        setCurrentHashCollectionVersion(
          supportedHashTypesCollection[data.collection_hash],
        );
      }
    }
  }, [allHashes]);

  const formattedDateTime = moment(
    data.time,
    "MMM-DD-YYYY hh:mm:ss A Z",
  ).format("MMM DD, YYYY | hh:mm:ss A");

  const [helpIsActive, setHelpIsActive] = useState(() => {
    const cookieValue = Cookies.get("helpIsActive");
    return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
  });

  const handleHelpTriggerClick = () => {
    const newHelpIsActive = !helpIsActive;
    setHelpIsActive(newHelpIsActive);
    Cookies.set("helpIsActive", JSON.stringify(newHelpIsActive));
  };

  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const remainingScroll = documentHeight - (scrollTop + windowHeight);

    if (
      (remainingScroll <= 80 && window.innerWidth <= 639) ||
      (remainingScroll <= 48 && window.innerWidth >= 640)
    ) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <img
        src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
        alt="Green checkmark"
        className="rb-result-img align-center"
      />
      <h2 className="rb-header rb-header-stamp rb-text-center">Stamp Found!</h2>
      <div
        id="stamp-found"
        className="rb-container rb-stamp-window rb-align-center"
      >
        <div className="scalloped-top"></div>
        <div className="scalloped-bottom"></div>
        <div className="scalloped-left"></div>
        <div className="scalloped-right"></div>
        <div className="rb-details-table">
          <div className="rb-account-info-wrap rb-display-flex rb-justify-content-center rb-align-items-center">
            <div className="rb-account-info rb-account-info-found__user">
              <div className="rb-account-info-key">Username</div>
              {data.user || data.user_id}
            </div>
            {data.authenticated ? (
              <div className="rb-account-info rb-account-info-status">
                <div className="rb-account-info-key">Verified Identity?</div>
                <img
                  src={`${staticBasePath}public/images/svg/verified.svg`}
                  alt="verified"
                  className="rb-verify-img"
                />
                Verified
              </div>
            ) : (
              <div className="rb-account-info rb-account-info-status">
                <div className="rb-account-info-key">Verified Identity?</div>
                Not Verified
              </div>
            )}
          </div>

          <div className="rb-account-info-collection-wrap">
            <div className="rb-account-info rb-account-info-collection">
              <span className="rb-account-info-key rb-account-info-key__time">
                Collection Name:{" "}
              </span>
              {data.collection_name
                ? data.collection_name
                : data.collection_hash
                ? "<<Not Available>>"
                : "<<None>>"}
            </div>

            <div className="rb-account-info rb-account-info-found__time">
              {helpIsActive && (
                <Help
                  position="top-right"
                  link=""
                  text={{
                    __html: `<p><b>Username</b> is the chosen account name or account ID of the user who made the stamp. In blockchain terms, an account ID is a wallet address.</p>
<p><b>Verified Identity</b> indicates whether vBase has confirmed the identity of the user.</p>
<p><b>Timestamp</b> is an emergent property of when the data fingerprint was added to the blockchain.</p>
<p><b>Collections</b> are assigned groups of stamps, useful for creating verifiable data across topics.</p>`,
                  }}
                />
              )}
              <span className="rb-account-info-key rb-account-info-key__time">
                Timestamp:{" "}
              </span>
              {formattedDateTime} +UTC&nbsp;&nbsp;{" "}
              <span className="rb-account-info-timedelta">
                {data.timedelta}
              </span>
            </div>
          </div>
          <div className="rb-details-table__header">
            <span>
              Technical Details
              {helpIsActive && (
                <Help
                  position="right"
                  link=""
                  text={{
                    __html: `
      <p><b>Blockchain Address</b> is the blockchain address of the user who made the stamp.</p>
      <p><b>Content ID</b> is the SHA3 fingerprint of the underlying stamped data.</p>
      <p><b>Collection ID</b> is a fingerprint of the Collection Name.</p>
      <p><b>Public Blockchain Record</b> is a link to a third-party tool showing the permanent location of your data on a blockchain!</p>
    `,
                  }}
                />
              )}
            </span>
          </div>

          <div className="rb-details-table__item">
            <div className="rb-details-table__item__header">
              Blockchain Address
            </div>
            <div className="rb-details-table__dotted-space">
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              id="stamp-found__account_id"
              className="rb-details-table__item__value"
            >
              {formatStampDetails(data.user_id)}
            </div>
            <img
              className="rb-details-table__copy"
              src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
              alt="copy"
              onClick={() => handleClickToCopy(data.user_id)}
            />
          </div>

          <div className="rb-details-table__item">
            <div className="rb-details-table__item__header">
              Content ID {currentHashVersion && <>({currentHashVersion})</>}
            </div>
            <div className="rb-details-table__dotted-space">
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              id="stamp-found__content-id"
              className="rb-details-table__item__value"
            >
              {formatStampDetails(data.objectCid)}
            </div>
            <img
              className="rb-details-table__copy"
              src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
              alt="copy"
              onClick={() => handleClickToCopy(data.objectCid)}
            />
          </div>

          <div className="rb-details-table__item">
            <div className="rb-details-table__item__header">
              Collection ID{" "}
              {currentHashCollectionVersion && (
                <>({currentHashCollectionVersion})</>
              )}
            </div>
            <div className="rb-details-table__dotted-space">
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              id="stamp-found__content-id"
              className="rb-details-table__item__value"
            >
              {data.collection_hash
                ? formatStampDetails(data.collection_hash)
                : "<<None>>"}
            </div>
            {data.collection_hash && (
              <img
                className="rb-details-table__copy"
                src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                alt="copy"
                onClick={() => handleClickToCopy(data.collection_hash)}
              />
            )}
          </div>

          <div className="rb-details-table__item">
            <div className="rb-details-table__item__header">
              <a
                href={`${data.blockExplorerUrl}${data.transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Public Blockchain Record
              </a>
            </div>
            <img
              className="rb-details-table__copy"
              src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
              alt="copy"
              onClick={() =>
                handleClickToCopy(
                  `${data.blockExplorerUrl}${data.transactionHash}`,
                )
              }
            />
          </div>

          {pdfShow && (
            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                <DownloadPDF
                  data={data}
                  formattedDateTime={formattedDateTime}
                  allHashes={allHashes}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {showVerifyStamp === true && (
        <div className="rb-container">
          <div className="rb-btn-help-wrap">
            <button
              className="rb-btn rb-btn-click rb-btn-stamp-window rb-display-flex rb-justify-center"
              onClick={handleDefaultScreen}
            >
              Verify Another Stamp
            </button>
            {helpIsActive && (
              <Help
                position="right"
                link=""
                text="You do not need to download or save any further receipt. To validate this information in the future, all you need is the file itself."
              />
            )}
          </div>
        </div>
      )}
      <TriggerHelp
        helpIsActive={helpIsActive}
        isNearBottom={isNearBottom}
        isAuthenticated="true"
        isOpenNotification={false}
        handleHelpTriggerClick={handleHelpTriggerClick}
      />
    </>
  );
};

StampFound.propTypes = {
  data: PropTypes.shape({
    objectCid: PropTypes.string,
    time: PropTypes.string,
    user: PropTypes.string,
    user_id: PropTypes.string,
    authenticated: PropTypes.bool,
    collection_name: PropTypes.string,
    timedelta: PropTypes.string,
    collection_hash: PropTypes.string,
    blockExplorerUrl: PropTypes.string,
    transactionHash: PropTypes.string,
  }).isRequired,
  staticBasePath: PropTypes.string.isRequired,
  handleDefaultScreen: PropTypes.func.isRequired,
  showVerifyStamp: PropTypes.bool,
  allHashes: PropTypes.object.isRequired,
};
export default StampFound;
